import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Affix, Alert, Layout, Spin} from "antd";
import * as serviceWorker from './serviceWorker';
import '../src/assets/custom.css';

if (process.env.REACT_APP_PROD_DESIGN)
    require('../src/assets/theme.less');
else
    require('antd/dist/antd.min.css');

const App = React.lazy(() => import('./App'));

ReactDOM.render(
    <React.StrictMode>
        {process.env.NODE_ENV !="production" ? <Affix>
            <Alert
                message="Demo Version (Only for testing purposes)"
                banner
                closable
            />
        </Affix>:null}
        <Suspense fallback={(
            <Spin size="large" tip={"Loading Application..."}>
                <div style={{width: '100vw', height: '100vh', background: "#aaa"}}/>
            </Spin>)}>
            <App/>
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
